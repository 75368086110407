import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  ForgotContainer,
  Subtitle,
  Title1,
  ErrorAlert,
  ErrorInfo,
  Message,
  TrackErrorMessage,
} from "../pageHelpers/Forgotpassword/ForgotpasswordHelpers";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import Form from "react-bootstrap/Form";
import { forgotPassword, resendActivationEmail } from "../services/crm";
import SuccessModal from "../components/common/SuccessModal/SuccessModal";
import { IUserInfo } from "../@types/IUserInfo";
import { Constants } from "../@types/Constants";
import ErrorMessageAlert from "../components/common/ErrorMessageAlert/ErrorMessageAlert";
import { guestServiceProvider } from "../services/ServiceProvider";
import { Storage } from "../utils/storage";
import { useForm } from "react-hook-form";

const Forgotpassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageWithLink, setErrorMessageWithLink] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showActivationBtn, setShowActivationBtn] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const { register, handleSubmit, errors, clearErrors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    clearErrors("email");
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setErrorMessageWithLink(false);
    setIsSubmitting(true);

    try {
      const guestService = await guestServiceProvider();
      const response = await guestService.sendResetPasswordEmail(email.trim());
      if (
        response.createResetPasswordEmail.statusCode === "400" ||
        response.createResetPasswordEmail.statusCode === "500"
      ) {
        setIsNotFound(true);
        setErrorMessage(
          "If there is an account with this email address, we will send you a link to set your new password."
        );
      } else {
        setSuccessMessage(
          "Please check your email inbox for a link to set your new password."
        );
        setShowSuccess(true);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Caught error in call to changePassword:", err);
      setIsNotFound(false);
      const errorMsg =
        "We cannot reset your password at this time. Please try again later.";
      setErrorMessage(errorMsg);
    }
    setIsSubmitting(false);
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
    setEmail("");
  };
  const handleResendActivationEmail = async () => {
    setIsResendingEmail(true);
    const data = {
      firstname: userInfo.firstname,
      lastname: userInfo.lastname,
      membernumber: userInfo.membernumber,
      oktaid: userInfo.oktaid,
      email: userInfo.email,
    };
    const response = await resendActivationEmail(data);
    if (!response || response.error) {
      setErrorMessageWithLink(true);
      setShowActivationBtn(false);
    } else {
      setSuccessMessage(
        "You will receive a confirmation email shortly to activate your profile."
      );
      setShowSuccess(true);
      setErrorMessage("");
    }
    setIsResendingEmail(false);
  };
  useEffect(() => {
    Storage.ClearLocalStorageValue(Constants.BRAND_BIAS_KEY);
  }, []);

  return (
    <Layout>
      <ForgotContainer>
        <SEO title={`Forgot Password`} />
        <Container fluid="sm" className="pt-5 mb-5">
          <Row>
            <Col lg={4}>
              <Subtitle>TRAVEL PASS </Subtitle>
              <Title1 id={"forgot-password-title"}>Forgot Password</Title1>
            </Col>
            <Col lg={{ span: 7, offset: 1 }} id="forgot-password-description">
              <p>
                <strong>
                  Please enter the email address associated with your account.
                </strong>
                <br />
                Enter your email, and we’ll send you a link to set your new
                password.
              </p>
              {errorMessage ? (
                <ErrorMessageAlert
                  errorType={Constants.ERRORSTYPE.TRAVELPASS}
                  errorSubType={
                    Constants.ERRORSSUBTYPE.TRAVELPASS.FORGOTPASSWORD
                  }
                  message={errorMessage}
                >
                  {!errorMessageWithLink ? (
                    <ErrorAlert
                      errorMessageWithLink={errorMessageWithLink}
                      errorMessage={errorMessage}
                      showActivationBtn={showActivationBtn}
                      handleResendActivationEmail={handleResendActivationEmail}
                      isResendingEmail={isResendingEmail}
                      isNotFound={isNotFound}
                    />
                  ) : (
                    <ErrorInfo />
                  )}
                </ErrorMessageAlert>
              ) : null}
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label id="email-address">Email Address</Form.Label>
                    <Form.Control
                      type={`email`}
                      name={`email`}
                      placeholder={`Email Address`}
                      value={email}
                      onChange={handleEmailChange}
                      disabled={isSubmitting}
                      aria-label="Email Address"
                      autoComplete="email"
                      id="email-value"
                      required
                      ref={register({
                        required: "This field is required",
                        pattern: {
                          value:
                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$|[0-9]{9}/i,
                          message: "A valid Email address is required.",
                        },
                      })}
                      isInvalid={errors.email}
                      aria-invalid={errors.email ? "true" : "false"}
                    />
                    <Form.Control.Feedback type="invalid">
                      <TrackErrorMessage
                        message={errors.email && errors.email.message}
                        field="email"
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={{ minWidth: `170px` }}
                  id="submit-forgot-password"
                >
                  {isSubmitting ? `Please wait...` : `Send Password Link`}
                </Button>
              </Form>
            </Col>
          </Row>
          <SuccessModal show={showSuccess} onHide={handleSuccessClose}>
            <Subtitle className="text-center mb-1" id={"confirmation"}>
              Check Your Email Inbox
            </Subtitle>
            <Message id="success-message" message={successMessage} />
          </SuccessModal>
        </Container>
      </ForgotContainer>
    </Layout>
  );
};

export default Forgotpassword;
