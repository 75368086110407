// extracted by mini-css-extract-plugin
export var alt = "Forgotpassword-module--alt--3ecfe";
export var container = "Forgotpassword-module--container--dd496";
export var desktopOnly = "Forgotpassword-module--desktopOnly--ba8b4";
export var main = "Forgotpassword-module--main--baa74";
export var message = "Forgotpassword-module--message--df1bb";
export var mobileOnly = "Forgotpassword-module--mobileOnly--9935f";
export var primaryAlert = "Forgotpassword-module--primaryAlert--db89f";
export var subtitle = "Forgotpassword-module--subtitle--d0d5d";
export var textLeft = "Forgotpassword-module--textLeft--3ba9b";
export var title1 = "Forgotpassword-module--title1--60a15";
export var visuallyHidden = "Forgotpassword-module--visuallyHidden--0fb95";